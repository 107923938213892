export const formatResponse = response => {
  const formattedData = response.data.data.map(item => ({
    ...item,
    reachOutReasonTitle: item?.reachOutReason?.title ?? '',
    formattedAddressableIssues: !item?.addressableIssues
      ? []
      : item?.addressableIssues.map(addressableIssue => ({
          ...addressableIssue
        })),
    userId: item?.createdBy?.fortpacId
  }));

  return { ...response, data: { ...response.data, data: formattedData } };
};
