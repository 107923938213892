import React from 'react';
import Badge from '@material-ui/core/Badge';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ErrorIcon from '@material-ui/icons/Error';
import {
  DEFAULT_MESSAGE_STATUS,
  MESSAGE_FAILURE_STATUSES
} from 'features/message-center/constants';
import { When } from 'UI/components/atoms/When';
import { extractFilenameFromUrl } from 'UI/utils';

import { formatInternationalPhone } from '../messageChat/chat.utils';

import { formatDate } from './Conversation.utils';
import { useStyles } from './styles';

export const Conversation = ({
  conversation,
  onClickConversation,
  isSelected,
  shouldTruncate = false
}) => {
  const {
    attachments,
    date,
    excerpt,
    externalNumber,
    fullName = '',
    message,
    messageStatus = DEFAULT_MESSAGE_STATUS,
    unreadMessages = 0
  } = conversation;

  const styles = useStyles({
    shouldTruncate
  });
  const hasErrorStatus = MESSAGE_FAILURE_STATUSES.includes(messageStatus);
  const hasAttachments = attachments?.length > 0;
  const hasUnreadMessages = unreadMessages > 0;
  const phoneNumber = formatInternationalPhone(externalNumber);

  const alternativeMessage = hasAttachments
    ? extractFilenameFromUrl(attachments[0]?.url ?? attachments[0])
    : '';

  const handleClickConversation = () => {
    onClickConversation && onClickConversation(conversation);
  };

  return (
    <ListItem
      aria-label="conversation"
      button
      className={styles.conversationContainer}
      onClick={handleClickConversation}
      selected={isSelected}
    >
      <ListItemText
        primary={
          <>
            <div className={styles.header}>
              <Typography variant="body2">
                <When condition={fullName}>
                  <span className={styles.contactName}>{fullName}</span>
                </When>
                <span>{phoneNumber}</span>
              </Typography>
              <Typography variant="body2">{formatDate(date)}</Typography>
            </div>
            <div className={styles.content}>
              <div className={styles.contentMessage}>
                {hasAttachments && (
                  <AttachFileIcon
                    aria-label="message with attachment"
                    color="disabled"
                    className={styles.icon}
                  />
                )}
                {message ? (
                  <Typography
                    className={styles.messageText}
                    dangerouslySetInnerHTML={{ __html: excerpt ?? message }}
                  />
                ) : (
                  <Typography variant="body2" className={styles.alternateMessage}>
                    {alternativeMessage}
                  </Typography>
                )}
              </div>

              {hasErrorStatus ? (
                <ErrorIcon aria-label="message with error" color="error" className={styles.icon} />
              ) : (
                <Badge
                  badgeContent={unreadMessages}
                  className={styles.unreadBadge}
                  color="primary"
                  invisible={!hasUnreadMessages}
                  max={9}
                />
              )}
            </div>
          </>
        }
      />
    </ListItem>
  );
};
