import isNil from 'lodash/isNil';
import { capitalizeFirstLetter, formatDuration } from 'UI/utils';

export const formatResponse = response => {
  const formattedData = response.data.data.map(item => ({
    ...item,
    type: capitalizeFirstLetter(item?.type ?? ''),
    duration: !isNil(item?.secDuration) ? formatDuration(item?.secDuration) : null,
    userId: item?.giverFortpacId
  }));

  return { ...response, data: { ...response.data, data: formattedData } };
};
