import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  getConversationMessages,
  resetUnreadMessages,
  sendMessageToServer
} from 'features/message-center';
import debounce from 'lodash/debounce';
import moment from 'moment';
import { useUserContext } from 'providers/UserProvider';
import { UIStatus } from 'UI/constants/status';
import { getErrorMessage } from 'UI/utils';

import { MESSAGE_DIRECTION_PARAM, MESSAGE_STATUS } from '../components/messageChat/chat.constants';
import {
  getInternalNumber,
  groupMessagesByDate,
  updateMessageStatusV2
} from '../components/messageChat/chat.utils';
import { DEFAULT_MESSAGE_STATUS, SEARCH_TYPES } from '../constants';

import { useNotificationHandler } from './useNotificationHandler';
import { findExtremeItemsByDate } from './utils';

const DEFAULT_PARAMS = {
  limit: 20,
  direction: MESSAGE_DIRECTION_PARAM.DESC
};

const resetUnread = async ({
  internalNumber,
  externalNumber,
  selectedConversationInList,
  onResetConversation
}) => {
  try {
    await resetUnreadMessages({
      internalNumber,
      externalNumber
    });

    selectedConversationInList?.id &&
      onResetConversation &&
      onResetConversation({
        id: selectedConversationInList.id,
        updates: {
          unreadMessages: 0
        }
      });
  } catch (error) {
    // empty block to ignore error
  }
};

export const useChatV2 = ({
  externalNumber = '',
  selectedConversationInList,
  onUpdateConversation,
  isEnabled = true
}) => {
  const isSearching = selectedConversationInList?.searchType === SEARCH_TYPES.message;
  const hasMoreFutureMessages = useRef(isSearching);
  const hasMorePastMessages = useRef(false);
  const hasRefreshedConversation = useRef(false);

  const [user] = useUserContext();
  const [shouldScrollToBottom, setShouldScrollToBottom] = useState(false);
  const [chatStatus, setChatStatus] = useState(UIStatus.Default);
  const [chat, setChat] = useState(null);
  const chatGroupedByDay = useMemo(() => groupMessagesByDate(chat?.messages ?? []), [chat]);

  const internalNumber = getInternalNumber(user);

  const debouncedResetUnread = useCallback(
    debounce(() => {
      resetUnread({
        externalNumber,
        internalNumber
      });
    }, 5000),
    [externalNumber, internalNumber]
  );

  const handleNewNotification = useCallback(
    incomingMessage => {
      if (!isEnabled) return;

      const isMessageForThisConversation =
        internalNumber === incomingMessage.internalNumber &&
        externalNumber === incomingMessage.externalNumber;

      if (!isMessageForThisConversation || !chat?.messages) return;

      setChat(prev => ({ ...prev, messages: [...(prev?.messages ?? []), incomingMessage] }));
      setShouldScrollToBottom(true);
      debouncedResetUnread();
    },
    [chat, debouncedResetUnread, externalNumber, internalNumber, isEnabled]
  );

  useNotificationHandler({
    onNewNotification: handleNewNotification,
    isEnabled
  });

  const fetchInitialConversation = useCallback(async () => {
    if (!isEnabled || !externalNumber || !internalNumber) return;
    setChatStatus(UIStatus.Loading);

    const shouldAddParams = isSearching && !hasRefreshedConversation.current;
    hasMoreFutureMessages.current = shouldAddParams;
    const searchParams = shouldAddParams ? { lastItemDate: selectedConversationInList?.date } : {};

    try {
      const response = await getConversationMessages({
        ...DEFAULT_PARAMS,
        internalNumber,
        externalNumber,
        ...searchParams
      });

      const formattedResponse = shouldAddParams
        ? { ...response, messages: [...response.messages, selectedConversationInList] }
        : response;

      setChat(formattedResponse);
      hasMorePastMessages.current = response?.hasMoreItems ?? false;
      setChatStatus(UIStatus.Success);
      setShouldScrollToBottom(true);

      if (isSearching || selectedConversationInList?.unreadMessages === 0) return;

      resetUnread({
        externalNumber,
        internalNumber,
        selectedConversationInList,
        onResetConversation: onUpdateConversation
      });
    } catch (error) {
      setChatStatus(UIStatus.Error);
    } finally {
      setShouldScrollToBottom(false);
    }
  }, [
    externalNumber,
    internalNumber,
    isEnabled,
    isSearching,
    onUpdateConversation,
    selectedConversationInList
  ]);

  useEffect(() => {
    fetchInitialConversation();

    return () => {
      setChat(null);
      setChatStatus(UIStatus.Default);
      hasRefreshedConversation.current = false;
    };
  }, [fetchInitialConversation]);

  const handleRefreshConversation = async () => {
    if (chatStatus === UIStatus.Loading) return;

    hasRefreshedConversation.current = true;
    hasMorePastMessages.current = true;
    hasMoreFutureMessages.current = false;
    await fetchInitialConversation();
  };

  const updateStatus = (status, messageToUpdate, error) => {
    setChat(prev => ({
      ...prev,
      messages: updateMessageStatusV2({ messages: prev.messages, messageToUpdate, status, error })
    }));
  };

  const buildErrorFromException = exception => {
    const statusCode = exception?.response?.status;
    const message = getErrorMessage(exception);
    const err = statusCode && message ? { statusCode, message } : null;
    return err;
  };

  const handleClickSend = async ({ message, attachment, from, to, contact }) => {
    const attachments = attachment?.url ? [attachment] : [];

    const shouldRefreshConversation =
      hasMoreFutureMessages.current && isSearching && !hasRefreshedConversation.current;

    shouldRefreshConversation && setChatStatus(UIStatus.Loading);

    const now = new Date().toISOString();
    const date = moment.utc().local();
    const newMessage = {
      messageId: now,
      attachments,
      date,
      isOutbound: true,
      message,
      recipient: to,
      sender: from,
      status: MESSAGE_STATUS.sending
    };

    const updatedMessages = [...(chat?.messages ?? []), newMessage];
    setChat(prev => ({ ...prev, messages: updatedMessages }));

    try {
      !shouldRefreshConversation && setShouldScrollToBottom(true);
      await sendMessageToServer({
        to,
        from,
        message,
        attachment,
        contact
      });
      updateStatus(MESSAGE_STATUS.success, newMessage);
      onUpdateConversation &&
        onUpdateConversation({
          id: selectedConversationInList?.id,
          updates: {
            message,
            date,
            attachments,
            messageStatus: DEFAULT_MESSAGE_STATUS
          }
        });

      shouldRefreshConversation && (await handleRefreshConversation());
    } catch (error) {
      const err = buildErrorFromException(error);
      updateStatus(MESSAGE_STATUS.error, newMessage, err);
    } finally {
      setShouldScrollToBottom(false);
    }
  };

  const handleClickRetry = async message => {
    try {
      updateStatus(MESSAGE_STATUS.sending, message);
      await sendMessageToServer(message);
      updateStatus(MESSAGE_STATUS.success, message);
    } catch (error) {
      const err = buildErrorFromException(error);
      updateStatus(MESSAGE_STATUS.error, message, err);
    }
  };

  const handleFetchMoreMessages = async (direction = MESSAGE_DIRECTION_PARAM.DESC) => {
    setShouldScrollToBottom(false);
    const isFetchingPast = direction === MESSAGE_DIRECTION_PARAM.DESC;

    if (!isEnabled || chatStatus === UIStatus.Loading) return;

    const hasMoreMessages = isFetchingPast
      ? hasMorePastMessages.current
      : hasMoreFutureMessages.current;

    if (!hasMoreMessages) return;

    setChatStatus(UIStatus.Loading);
    const { oldest: oldestMessage, newest: newestMessage } = findExtremeItemsByDate(chat?.messages);

    try {
      const params = {
        ...DEFAULT_PARAMS,
        internalNumber,
        externalNumber,
        lastItemDate:
          direction === MESSAGE_DIRECTION_PARAM.DESC ? oldestMessage?.date : newestMessage?.date,
        direction
      };

      const response = await getConversationMessages(params);

      const messages = [...chat.messages, ...response.messages];
      setChat(prev => ({
        ...prev,
        ...response,
        messages
      }));

      if (isFetchingPast) {
        hasMorePastMessages.current = response?.hasMoreItems ?? false;
      } else {
        hasMoreFutureMessages.current = response?.hasMoreItems ?? false;
      }
      setChatStatus(UIStatus.Success);
    } catch (error) {
      setChatStatus(UIStatus.Error);
    }
  };

  return {
    chat: chatGroupedByDay,
    chatStatus,
    handleClickRetry,
    handleClickSend,
    handleFetchMoreMessages,
    handleRefreshConversation,
    shouldScrollToBottom
  };
};
