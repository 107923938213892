import Grid from '@material-ui/core/Grid';
import { styled } from '@material-ui/core/styles';
import DataTable from 'UI/components/organisms/DataTable';

export const Container = styled(Grid)({
  width: '100%',
  height: '100%'
});

export const TableContainer = styled(Grid)({
  flex: 1,
  overflow: 'auto'
});

export const ScrollableDataTable = styled(DataTable)({
  width: '100%',
  display: 'flex',
  flexDirection: 'column'
});
