import React, { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import { ENDPOINTS } from 'features/command-center/constants';
import strings from 'strings';
import TextBox from 'UI/components/atoms/TextBox';
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
import FPTextEditor from 'UI/components/molecules/FPTextEditor';
import { tinymceConfig } from 'UI/constants/config';
import { idOptionSelected, REQUIRED_VALIDATION, titleOptionLabel } from 'UI/utils';

import { FORM_FIELDS_MAP } from './FeedbackNoteForm.constants';

const { feedbackNotes: STRINGS } = strings.commandCenter;

export const FeedbackNoteForm = () => {
  const editorRef = useRef(null);

  const { errors, register, unregister, watch, setValue } = useFormContext();
  const formValues = watch();

  const initEditor = (e, editor) => {
    editorRef.current = editor;
  };

  const handleSelectChange = (name?, value) =>
    setValue(name, Array.isArray(value) && !value.length ? null : value, true);

  const handleEditorChange = editorValue =>
    setValue(FORM_FIELDS_MAP.Description.key, editorValue, true);

  const handleContractFieldChange = (fieldName, newValue) => {
    setValue(fieldName, newValue, true);
  };

  useEffect(() => {
    register(FORM_FIELDS_MAP.ReachOutReason.key, REQUIRED_VALIDATION);
    register(FORM_FIELDS_MAP.AddressableIssues.key, REQUIRED_VALIDATION);
    register(FORM_FIELDS_MAP.Description.key, REQUIRED_VALIDATION);
    register(FORM_FIELDS_MAP.Contract.key, REQUIRED_VALIDATION);
    return () => {
      unregister(FORM_FIELDS_MAP.ReachOutReason.key);
      unregister(FORM_FIELDS_MAP.AddressableIssues.key);
      unregister(FORM_FIELDS_MAP.Description.key);
      unregister(FORM_FIELDS_MAP.Contract.key);
    };
  }, [register, unregister]);

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item sm={12}>
        <AutocompleteSelect
          name={FORM_FIELDS_MAP.ReachOutReason.key}
          selectedValue={formValues[FORM_FIELDS_MAP.ReachOutReason.key]}
          placeholder={STRINGS.drawer.form.placeholders.reachOutReason}
          error={!!errors[FORM_FIELDS_MAP.ReachOutReason.key]}
          errorText={errors[FORM_FIELDS_MAP.ReachOutReason.key]?.message}
          api="secondary"
          url={ENDPOINTS.reachOutReasons}
          getOptionLabel={option => option.title}
          getOptionSelected={idOptionSelected}
          onSelect={handleSelectChange}
        />
      </Grid>
      <Grid item sm={12}>
        <AutocompleteSelect
          name={FORM_FIELDS_MAP.AddressableIssues.key}
          error={!!errors[FORM_FIELDS_MAP.AddressableIssues.key]}
          errorText={errors[FORM_FIELDS_MAP.AddressableIssues.key]?.message}
          getOptionLabel={option => option.title}
          getOptionSelected={idOptionSelected}
          multiple
          onSelect={handleSelectChange}
          selectedValue={formValues[FORM_FIELDS_MAP.AddressableIssues.key] || []}
          placeholder={STRINGS.drawer.form.placeholders.addressableIssues}
          renderOption={titleOptionLabel}
          api="secondary"
          url={ENDPOINTS.addressableIssues}
        />
      </Grid>
      <Grid item sm={12}>
        <FPTextEditor
          onInit={initEditor}
          config={tinymceConfig}
          value={formValues[FORM_FIELDS_MAP.Description.key]}
          onChange={handleEditorChange}
          error={!!errors[FORM_FIELDS_MAP.Description.key]}
          errorText={errors[FORM_FIELDS_MAP.Description.key]?.message}
        />
      </Grid>
      <Grid item sm={12}>
        <TextBox
          label={STRINGS.drawer.form.placeholders.contract}
          name={FORM_FIELDS_MAP.Contract.key}
          error={!!errors[FORM_FIELDS_MAP.Contract.key]}
          errorText={errors[FORM_FIELDS_MAP.Contract.key]?.message}
          onChange={handleContractFieldChange}
          value={formValues[FORM_FIELDS_MAP.Contract.key] || ''}
        />
      </Grid>
    </Grid>
  );
};
