// @flow
import React from 'react';
import Chip from '@material-ui/core/Chip';
import strings from 'strings';
import { SvgCheck, SvgDelete, SvgWarning } from 'UI/res';
import { nestTernary } from 'UI/utils';

const Origins = {
  Table: 'table',
  Profile: 'profile'
};
const EmailTypes = {
  Primary: 'primary',
  Secondary: 'secondary'
};

type EmailStatusChipProps = {
  status: 'valid' | 'invalid' | 'risky',
  origin: 'table' | 'profile',
  emailType: 'primary' | 'secondary'
};

const {
  invalid: invalidCopy,
  valid: validCopy,
  risky: riskyCopy
} = strings.inventoryProfiles.common.emailValidationStatus;

const ChipConfig = {
  valid: {
    icon: <SvgCheck size={12} />,
    className: 'chip-valid-email',
    label: validCopy
  },
  invalid: {
    icon: <SvgDelete size={12} />,
    className: 'chip-invalid-email',
    label: invalidCopy
  },
  risky: {
    icon: <SvgWarning size={12} />,
    className: 'chip-risky-email',
    label: riskyCopy
  }
};

const EmailStatusChip = ({
  status,
  origin = Origins.Table,
  emailType = EmailTypes.Primary
}: EmailStatusChipProps) => {
  if (!ChipConfig[status]) return null;

  const isProfile = origin === Origins.Profile;
  const isPrimary = emailType === EmailTypes.Primary;

  const emailTypePrefix = isProfile
    ? nestTernary(isPrimary, strings.shared.fields.email, strings.shared.fields.otherEmail)
    : '';

  return (
    <Chip
      icon={ChipConfig[status].icon}
      variant="outlined"
      size="small"
      className={ChipConfig[status].className}
      label={`${ChipConfig[status].label} ${emailTypePrefix}`}
    />
  );
};

export default EmailStatusChip;
