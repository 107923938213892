import { useCallback, useEffect, useState } from 'react';
import { getConversation, resetUnreadMessages, sendMessageToServer } from 'features/message-center';
import debounce from 'lodash/debounce';
import moment from 'moment';
import { useUserContext } from 'providers/UserProvider';
import { DateFormats } from 'UI/constants/defaults';
import { UIStatus } from 'UI/constants/status';
import { getErrorMessage } from 'UI/utils';

import { MESSAGE_DIRECTION_PARAM, MESSAGE_STATUS } from '../components/messageChat/chat.constants';
import {
  addMessageToChat,
  getDatePart,
  getInternalNumber,
  groupMessagesByDate,
  mergeMessages,
  updateMessageStatus
} from '../components/messageChat/chat.utils';
import { DEFAULT_MESSAGE_STATUS } from '../constants';

import { useNotificationHandler } from './useNotificationHandler';
import { formatAttachmentsInResponse } from './utils';

const DEFAULT_PARAMS = {
  limit: 20
};

const resetUnread = async ({
  internalNumber,
  externalNumber,
  selectedConversationInList,
  onResetConversation
}) => {
  try {
    await resetUnreadMessages({
      internalNumber,
      externalNumber
    });

    selectedConversationInList?.id &&
      onResetConversation &&
      onResetConversation({
        id: selectedConversationInList.id,
        updates: {
          unreadMessages: 0
        }
      });
  } catch (error) {
    // empty block to ignore error
  }
};

export const useChat = ({
  externalNumber = '',
  selectedConversationInList,
  onUpdateConversation,
  isEnabled = true
}) => {
  const [shouldScrollToBottom, setShouldScrollToBottom] = useState(false);
  const [user] = useUserContext();
  const [chat, setChat] = useState(null);
  const [chatStatus, setChatStatus] = useState(UIStatus.Default);

  const internalNumber = getInternalNumber(user);

  const debouncedResetUnread = useCallback(
    debounce(() => {
      resetUnread({
        externalNumber,
        internalNumber
      });
    }, 5000),
    [externalNumber, internalNumber]
  );

  const handleNewNotification = useCallback(
    incomingMessage => {
      if (!isEnabled) return;

      const isMessageForThisConversation =
        internalNumber === incomingMessage.internalNumber &&
        externalNumber === incomingMessage.externalNumber;

      if (!isMessageForThisConversation || !chat?.messages) return;

      const date = getDatePart(incomingMessage.date);

      setChat(prev => ({
        ...prev,
        messages: {
          ...prev.messages,
          [date]: [...(prev.messages[date] ?? []), incomingMessage].sort(
            (a, b) => new Date(a.date) - new Date(b.date)
          )
        }
      }));
      setShouldScrollToBottom(true);
      debouncedResetUnread();
    },
    [chat, debouncedResetUnread, externalNumber, internalNumber, isEnabled]
  );

  useNotificationHandler({
    onNewNotification: handleNewNotification,
    isEnabled
  });

  const hasMoreMessages = !!chat?.lastItem?.date;

  const fetchInitialConversation = useCallback(async () => {
    if (!isEnabled || !externalNumber || !internalNumber) return;
    setChatStatus(UIStatus.Loading);
    setChat(null);

    try {
      const response = await getConversation({
        ...DEFAULT_PARAMS,
        internalNumber,
        externalNumber
      });
      const formattedMessages = formatAttachmentsInResponse(response.messages);
      const messages = groupMessagesByDate(formattedMessages);

      setChat({ ...response, messages });
      setChatStatus(UIStatus.Success);
      setShouldScrollToBottom(true);

      if (selectedConversationInList?.unreadMessages === 0) return;

      resetUnread({
        externalNumber,
        internalNumber,
        selectedConversationInList,
        onResetConversation: onUpdateConversation
      });
    } catch (error) {
      setChatStatus(UIStatus.Error);
    } finally {
      setShouldScrollToBottom(false);
    }
  }, [externalNumber, internalNumber, selectedConversationInList, onUpdateConversation, isEnabled]);

  useEffect(() => {
    fetchInitialConversation();

    return () => {
      setChat(null);
      setChatStatus(UIStatus.Default);
    };
  }, [externalNumber, fetchInitialConversation, internalNumber]);

  const updateStatus = (status, messageToUpdate, error) => {
    setChat(prev => ({
      ...prev,
      messages: updateMessageStatus({ chat: prev.messages, messageToUpdate, status, error })
    }));
  };

  const buildErrorFromException = exception => {
    const statusCode = exception?.response?.status;
    const message = getErrorMessage(exception);
    const err = statusCode && message ? { statusCode, message } : null;
    return err;
  };

  const handleClickSend = async ({ message, attachment, from, to, contact }) => {
    setShouldScrollToBottom(true);
    const date = moment.utc().local();
    const day = date.format(DateFormats.SimpleDate);
    const newId = chat?.messages?.[day] ? chat.messages[day].length + 1 : 1;

    const newMessage = {
      id: newId,
      attachment,
      contact,
      date,
      day,
      from,
      isOutbound: true,
      message,
      status: MESSAGE_STATUS.sending,
      to
    };
    setChat(prev => ({ ...prev, messages: addMessageToChat(prev.messages, newMessage) }));

    try {
      await sendMessageToServer(newMessage);
      updateStatus(MESSAGE_STATUS.success, newMessage);
      onUpdateConversation &&
        onUpdateConversation({
          id: selectedConversationInList?.id,
          updates: {
            message,
            date,
            attachments: attachment?.url ? [attachment] : [],
            messageStatus: DEFAULT_MESSAGE_STATUS
          }
        });
    } catch (error) {
      const err = buildErrorFromException(error);
      updateStatus(MESSAGE_STATUS.error, newMessage, err);
    } finally {
      setShouldScrollToBottom(false);
    }
  };

  const handleClickRetry = async message => {
    try {
      updateStatus(MESSAGE_STATUS.sending, message);
      await sendMessageToServer(message);
      updateStatus(MESSAGE_STATUS.success, message);
    } catch (error) {
      const err = buildErrorFromException(error);
      updateStatus(MESSAGE_STATUS.error, message, err);
    }
  };

  const handleFetchMoreMessages = async (direction = MESSAGE_DIRECTION_PARAM.DESC) => {
    setShouldScrollToBottom(false);
    if (
      !isEnabled ||
      chatStatus === UIStatus.Loading ||
      !chat?.lastItem ||
      direction !== MESSAGE_DIRECTION_PARAM.DESC
    ) {
      return;
    }

    try {
      setChatStatus(UIStatus.Loading);
      const params = {
        ...DEFAULT_PARAMS,
        internalNumber,
        externalNumber,
        lastItemDate: chat.lastItem.date,
        lastItemInternalExternalNumbers: chat.lastItem.internalExternalNumbers
      };

      const response = await getConversation(params);
      const formattedMessages = formatAttachmentsInResponse(response.messages);

      const messages = mergeMessages({
        originalChat: chat.messages,
        messages: formattedMessages
      });
      setChat(prev => ({
        ...prev,
        messages,
        lastItem: response?.lastItem ?? null
      }));
      setChatStatus(UIStatus.Success);
    } catch (error) {
      setChatStatus(UIStatus.Error);
    }
  };

  const handleRefreshConversation = async () => {
    if (chatStatus === UIStatus.Loading) return;

    await fetchInitialConversation();
  };

  return {
    chat: chat?.messages ?? {},
    chatStatus,
    handleClickRetry,
    handleClickSend,
    handleFetchMoreMessages,
    handleRefreshConversation,
    hasMoreMessages,
    shouldScrollToBottom
  };
};
