import { makeStyles, styled } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

export const Container = styled('div')(({ theme }) => ({
  height: '100%',
  padding: theme.spacing(4),
  width: '100%'
}));

export const LoaderContaier = styled('div')({
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  justifyContent: 'center'
});

export const NotesLabel = styled(Typography)(({ theme }) => ({
  fontSize: '18px',
  fontWeight: '700 !important',
  lineHeight: '18px',
  color: theme.typography.fontWeightBold
}));

export const useStyles = makeStyles(theme => ({
  emailBodyRenderer: {
    border: `1px solid ${theme.palette.customColors.iconInactive}`,
    borderRadius: theme.spacing(0.5),
    flexGrow: '1',
    height: 500,
    marginBottom: theme.spacing(1),
    overflowY: 'scroll',
    padding: theme.spacing(1.5),
    width: '100%'
  }
}));
